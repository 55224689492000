import { render, staticRenderFns } from "./stateprescriptList.vue?vue&type=template&id=4e01c961&scoped=true&"
import script from "./stateprescriptList.vue?vue&type=script&lang=js&"
export * from "./stateprescriptList.vue?vue&type=script&lang=js&"
import style0 from "./stateprescriptList.vue?vue&type=style&index=0&id=4e01c961&prod&scoped=true&lang=css&"
import style1 from "./stateprescriptList.vue?vue&type=style&index=1&id=4e01c961&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e01c961",
  null
  
)

export default component.exports